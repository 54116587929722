import React, { useEffect, useState } from "react";
import { Button, Table, Row, Input, Col, FormGroup, ButtonGroup } from "reactstrap";
import swal from "sweetalert";
import { PDFExport } from "@progress/kendo-react-pdf";

import { addMember } from "../ApiMember";
import {
  GetLanguageString,
  GetSortingUI,
  NoResult,
  SetReportTitle,
} from "../../helper/Components";
import SearchReport from "./SearchReport";
import { GetCustomStyles, GetSelectedLanguage } from "../../helper/Method";
import SendSMSModal from "../../SMSMessage/SendSMS";
import { LoadingSpinner, isUrdu } from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { t } from "i18next";
import { saveAs } from "file-saver";
import useSorting from "../../shared/useSorting";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";

const initialValues = {
  name: "neutral",
  joiningDate: "neutral",
  regNo: "neutral",
  memberType: "neutral",
  responsbilities: "neutral",
};

const ReportList = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const memberId = parseInt(encryptStorage.getItem("memberId"));
  const [members, setMembers] = useState([]);
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [memberSMSStatus, setMemberSMSStatus] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  const [selecteAllMembers, setSelecteAllMembers] = useState(false);
  const [smsModal, setSmsModal] = useState(false);
  const [reportSearchMemberModel, setReportSearchMemberModel] = useState({
    isSubUnits: true,
    includeEXMember: false,
    complete: true,
    inComplete: false,
    includeCourse: true,
    attendanceStatus: 0,
    isOnTime: true,
    unitId: loggedInUnitId,
    loggedInUnitId: loggedInUnitId,
    courseStartDate: null,
    courseEndDate: null,
    eventStartDate: null,
    eventEndDate: null,
    serachButton: true,
  });

  const { sortObject, handleSorting } = useSorting(initialValues);

  const pdfExportComponent = React.useRef(null);

  // const exportPDFWithComponent = () => {
  //   if (pdfExportComponent.current) {
  //     pdfExportComponent.current.save();
  //   }
  // };

  useEffect(() => {
    if (searchButton) {
      setLoading(true);
      let tempSortObject = {
        isFullNameDescending: sortObject.name === "descending",
        isFullNameAscending: sortObject.name === "ascending",
        isJoiningDateDescending: sortObject.joiningDate === "descending",
        isJoiningDateAscending: sortObject.joiningDate === "ascending",
        isRegNoAscending: sortObject.regNo === "ascending",
        isRegNoDescending: sortObject.regNo === "descending",
        isMemberTypeAscending: sortObject.memberType === "ascending",
        isMemberTypeDescending: sortObject.memberType === "descending",
        isResponsbilitiesAscending: sortObject.responsbilities === "ascending",
        isResponsbilitiesDescending:
          sortObject.responsbilities === "descending",
      };
      reportSearchMemberModel.sortModel = tempSortObject;

      addMember()
        .getMembersReport(reportSearchMemberModel)
        .then((res) => {
          setMembers(res.data);
          let temp = [];
          res.data.forEach((m) => temp.push({ status: false }));
          setSelectedMembers(temp);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [reportSearchMemberModel, sortObject, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  useEffect(() => {
    addMember()
      .checkMemberCanSendSMS(memberId)
      .then((res) => {
        setMemberSMSStatus(res.data);
      })
      .catch((err) => console.log(err));
  }, [memberId]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const excelDownload = () => {
    addMember()
      .generateMembersReport(members)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "Member.xlsx");
      })
      .catch((err) => console.log(err));
  };

  const handleChangeStatus = (status, index, number) => {
    let tempList = [...selectedMembers];
    tempList[index].status = status;
    if (status) tempList[index].mobileNo = number;
    setSelectedMembers(tempList);
  };

  const handleSelectAllMembers = () => {
    let temp = [];
    if (selecteAllMembers) {
      members.forEach((m) => temp.push({ status: false }));
    } else {
      members.forEach((m) => temp.push({ status: true, mobileNo: m.phone }));
    }
    setSelectedMembers(temp);
    setSelecteAllMembers(!selecteAllMembers);
  };

  const IsMemberSelected = () => {
    let memberStatus = false;
    for (let i = 0; i < selectedMembers.length; i++) {
      if (selectedMembers[i].status) {
        memberStatus = true;
        break;
      }
    }
    return memberStatus;
  };

  const handleSMSModel = () => {
    if (IsMemberSelected()) {
      setSmsModal(true);
    } else {
      swal({
        title: t("sms_error"),
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        icon: "warning",
      });
    }
  };

  return (
    <div>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      {smsModal ? (
        <SendSMSModal
          setModal={setSmsModal}
          memberList={selectedMembers}
          memberListLength={selectedMembers.length}
        />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <div className="ibox noprint">
          <div className="ibox-title">
            <h5>{<GetLanguageString props="common_generate_report" />}</h5>
          </div>
          <div className="ibox-content">
            <SearchReport
              reportSearchMemberModel={reportSearchMemberModel}
              setReportSearchMemberModel={setReportSearchMemberModel}
              setSearchButton={setSearchButton}
            />
            {memberId !== 0 && memberSMSStatus ? (
              <Row className="m-t-md">
                <Col>
                  <FormGroup>
                    <Button color="success" onClick={handleSMSModel}>
                      {<GetLanguageString props="sms_send_sms" />}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
          </div>
        </div>
        {searchButton ? (
          !loading ? (
            <div className="ibox">
              <div className="ibox-title">
                <h5>
                  {<GetLanguageString props="member_report_members_details" />}
                </h5>
                {members.length !== 0 ? (
                  <>
                  <ButtonGroup className={GetCustomStyles().btn_group_style}>
                    <Button
                      color="default"
                      size="sm"
                      onClick={excelDownload}
                    >
                      <RiFileExcel2Line
                        size={15}
                        color="#009440"
                        style={{ marginRight: 3, marginBottom: 1 }}
                      />
                      {<GetLanguageString props="member_report_export_excel" />}
                    </Button>
                    <Button
                      color="default"
                      size="sm"
                      onClick={handleReportTitle}
                    >
                      <FcPrint
                        size={15}
                        className={GetCustomStyles().fa_style}
                      />
                      {<GetLanguageString props="common_print" />}
                    </Button>
                    </ButtonGroup>
                  </>
                ) : null}
              </div>
              <PDFExport
                ref={pdfExportComponent}
                fileName="MembersReport"
                scale={0.6}
                paperSize="A4"
                alignItems="center"
              >
                <div className="ibox-content ibox-center" id="section-to-print">
                  <Row className="print">
                    <div className="text-center">
                      <h3>Title : {reportTitle}</h3>
                    </div>
                  </Row>
                  {members.length !== 0 &&
                  members.length === selectedMembers.length ? (
                    <div className="table-responsive">
                      <Table className="table-bordered">
                        <thead>
                          <tr>
                            {memberId !== 0 && memberSMSStatus ? (
                              <th className="noprint">
                                <Input
                                  id="selectAll"
                                  type="checkbox"
                                  checked={selecteAllMembers}
                                  name="selectAll"
                                  className="big-checkbox"
                                  onChange={handleSelectAllMembers}
                                />
                              </th>
                            ) : null}
                            <th>#</th>
                            <th
                              className="cursor-pointer"
                              onClick={() => handleSorting("regNo")}
                            >
                              <GetLanguageString props="member_reg_number" />
                              <GetSortingUI
                                sortObject={sortObject}
                                name="regNo"
                                isReport
                              />
                            </th>
                            <th
                              className="cursor-pointer"
                              onClick={() => handleSorting("name")}
                            >
                              <GetLanguageString props="common_name" />
                              <GetSortingUI
                                sortObject={sortObject}
                                name="name"
                                isReport
                              />
                            </th>
                            <th>
                              {<GetLanguageString props="member_address" />}
                            </th>
                            <th>
                              {<GetLanguageString props="member_phone" />}
                            </th>
                            <th
                              className="cursor-pointer"
                              onClick={() => handleSorting("memberType")}
                            >
                              <GetLanguageString props="settings_member_type" />
                              <GetSortingUI
                                sortObject={sortObject}
                                name="memberType"
                                isReport
                              />
                            </th>
                            <th
                              className="cursor-pointer"
                              onClick={() => handleSorting("responsbilities")}
                            >
                              <GetLanguageString props="member_member_detail_common_responsibility" />
                              <GetSortingUI
                                sortObject={sortObject}
                                name="responsbilities"
                                isReport
                              />
                            </th>
                            <th>
                              {<GetLanguageString props="unit_unit_table" />}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {members?.map((record, index) => {
                            return (
                              <tr key={index}>
                                {memberId !== 0 && memberSMSStatus ? (
                                  <td className="noprint">
                                    <Input
                                      id={"select " + index}
                                      type="checkbox"
                                      checked={selectedMembers[index].status}
                                      className="big-checkbox"
                                      onChange={() =>
                                        handleChangeStatus(
                                          !selectedMembers[index].status,
                                          index,
                                          record.phone
                                        )
                                      }
                                    />
                                  </td>
                                ) : null}
                                <td>{index + 1}</td>
                                <td>{record.regNo}</td>
                                <td className="ur">{record.fullName}</td>
                                <td
                                  className={
                                    isUrdu(record.currentAddress) ? "ur" : ""
                                  }
                                >
                                  {record.currentAddress}
                                </td>
                                <td>
                                  {record.phone
                                    .split(",")
                                    .map((phoneNumber, index) => (
                                      <div key={index}>{phoneNumber}</div>
                                    ))}
                                </td>
                                <td className="ur">{record.memberType}</td>
                                <td>
                                  {record.responsbilities.map((item, index) => {
                                    return (
                                      <Button
                                        key={index}
                                        size="xs"
                                        color="info"
                                        className="label label-info m-r-xs"
                                      >
                                        <h5 className="ur">{item}</h5>
                                      </Button>
                                    );
                                  })}
                                </td>
                                <td className="ur">{record.unitName}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <NoResult />
                  )}
                </div>
              </PDFExport>
            </div>
          ) : (
            LoadingSpinner()
          )
        ) : null}
      </div>
    </div>
  );
};

export default ReportList;
