import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { requestApi } from "../../../actions/api";
import { ApiNotificationType } from "./ApiNotificationType";
import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import swal from "sweetalert";
import Draggable from "react-draggable";

const EditNotificationType = ({
  editValue,
  setEditModal,
  setNotificationType,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [notificationTypeValues, setNotificationTypeValues] = useState({
    code: "",
    name: "",
    isApprovalProcess: false,
  });
  const [errors, setErrors] = useState(notificationTypeValues);
  const toggle = () => setEditModal(false);
  const [recipientOptions, setRecipientOptions] = useState([]);
  const [approvalBodyOptions, setApprovalBodyOptions] = useState([]);
  const [selectedRecipientOptions, setSelectedRecipientOptions] = useState([]);
  const [removedRecipientOptions, setRemovedRecipientOptions] = useState([]);
  const [removedApprovalBodyOptions, setRemovedApprovalBodyOptions] = useState(
    []
  );
  const [selectedApprovalBodyOptions, setSelectedApprovalBodyOptions] =
    useState([]);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  useEffect(() => {
    setLoading(true);
    requestApi("setupnotificationtype/")
      .fetchById(editValue)
      .then((res) => {
        setNotificationTypeValues(res.data);
        populateResponsibilityOptions(
          res.data.recipientIds,
          res.data.approvalBodyIds
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
      });
  }, [editValue]);

  const populateResponsibilityOptions = (recipientIds, approvalBodyIds) => {
    setLoading(true);
    ApiNotificationType()
      .getResponsibilityOptions()
      .then((res) => {
        // render recipient options
        let selectedRecipients = [];
        let selectedApprovalBodies = [];

        if (res.data !== null) {
          setRecipientOptions(res.data);
          setApprovalBodyOptions(res.data);

          res.data.forEach((d) => {
            if (recipientIds.some((item) => d.value === item))
              selectedRecipients.push(d);
            if (approvalBodyIds.some((item) => d.value === item))
              selectedApprovalBodies.push(d);
          });
        }
        setSelectedRecipientOptions(selectedRecipients);
        setSelectedApprovalBodyOptions(selectedApprovalBodies);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
      });
  };
  const validate = () => {
    let temp = {};
    temp.code = notificationTypeValues.code
      ? ""
      : t("settings_code_error_message");
    temp.name = notificationTypeValues.name
      ? ""
      : t("settings_name_error_message");

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      code: "",
      name: "",
    });
    setNotificationTypeValues({
      ...notificationTypeValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    notificationTypeValues.removedRecipients = [].concat(
      ...removedRecipientOptions
    );
    notificationTypeValues.removedApprovalBodies = [].concat(
      ...removedApprovalBodyOptions
    );
    if (validate()) {
      setLoading(true);
      requestApi("setupnotificationtype/")
        .update(editValue, notificationTypeValues)
        .then((res) => {
          requestApi("setupnotificationtype/")
            .fetchAll()
            .then((res) => {
              toggle();
              setNotificationType(res.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              swal({
                title: err,
                icon: "error",
                buttons: "OK",
              });
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          setLoading(false);
        });
    }
  };

  const handleApprovalStatusChange = (e) => {
    const { value } = e.target;
    setNotificationTypeValues({
      ...notificationTypeValues,
      isApprovalProcess: value === "true",
    });
  };

  const handleChangeRecipients = (record) => {
    let newItem = selectedRecipientOptions.filter(
      (value) => !record.includes(value)
    );
    setRemovedRecipientOptions((prevArray) => [...prevArray, newItem]);

    setSelectedRecipientOptions(record);
    setNotificationTypeValues({
      ...notificationTypeValues,
      recipientIds: record.map((l) => l.value),
    });
  };

  const handleChangeApprovalBodies = (record) => {
    let newItem = selectedApprovalBodyOptions.filter(
      (value) => !record.includes(value)
    );
    setRemovedApprovalBodyOptions((prevArray) => [...prevArray, newItem]);
    setSelectedApprovalBodyOptions(record);
    setNotificationTypeValues({
      ...notificationTypeValues,
      approvalBodyIds: record.map((l) => l.value),
    });
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_edit_notification_type" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_notification_type_code" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="code"
                    className="no-drag"
                    value={notificationTypeValues.code}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_notification_type_name" />
                    }
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="name"
                    className="no-drag"
                    value={notificationTypeValues.name}
                    onChange={handleInputChange}
                    {...(errors.type && { invalid: true })}
                  />
                  {errors.name && (
                    <FormFeedback>
                      <h4>{errors.name}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>

                <FormGroup check>
                  <Label check for="isApprovalProcess">
                    <Input
                      id="isApprovalProcess"
                      type="checkbox"
                      name="isApprovalProcess"
                      value={!notificationTypeValues.isApprovalProcess}
                      onChange={handleApprovalStatusChange}
                      checked={notificationTypeValues.isApprovalProcess}
                    />
                    {
                      <GetLanguageString props="settings_notification_type_is_approval_process" />
                    }
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">Select Recipients</Label>
                  <Select
                    value={selectedRecipientOptions}
                    options={recipientOptions}
                    className="basic-multi-select ur"
                    classNamePrefix="select"
                    isMulti
                    onChange={handleChangeRecipients}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    Select Approval Bodies
                  </Label>
                  <Select
                    value={selectedApprovalBodyOptions.filter(
                      (option) => !option.isDisabled
                    )}
                    options={approvalBodyOptions}
                    isDisabled={!notificationTypeValues.isApprovalProcess}
                    className="basic-multi-select ur"
                    classNamePrefix="select"
                    isMulti
                    onChange={handleChangeApprovalBodies}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default EditNotificationType;
