import React, { useState, useEffect } from "react";

import {
  Button,
  ButtonGroup,
  Row,
  Table,
} from "reactstrap";
import { FcPrint } from "react-icons/fc";
import { RiFileExcel2Line } from "react-icons/ri";
import { saveAs } from "file-saver";
import moment from "moment";

import { GetLanguageString, SetReportTitle } from "../../../helper/Components";
import { GetCustomStyles } from "../../../helper/Method";
import { convertToDateString, LoadingSpinner } from "../../../../constants/const";
import { encryptStorage } from "../../../../constants/EncryptStorage";
import SearchArrivalsDeparturesCummulative from "./SearchArrivalsDeparturesCummulative";
import { addMember } from "../../../member/ApiMember";

const ArrivalsDeparturesCummulative = () => {
  const [loading, setLoading] = useState(false);
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [reportTitle, setReportTitle] = useState("");
  const [modal, setModal] = useState(false);
  const [unitMemberCounts, setUnitMemberCounts] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  
  let tempDate = new Date();
  tempDate.setUTCDate(1);
  const [searchValue, setSearchValue] = useState({
    unitId: loggedInUnitId,
    startDate: tempDate,
    endDate: new Date(),
    showSelectedUnitsCheck: "None",
    excludeExUnit: true,
    includeDisabledUnit: false,
  });
  let totalCount = {
    transferredToMemberCount: 0,
    arrivalsCount: 0,
    newMemberCount: 0,
    departuresCount: 0,
    transferredFromMemberCount: 0,
    ikhrajCount: 0,
    mazratCount: 0,
    fautShudahCount: 0,
    mafqoodUlKhabarCount: 0, 
  };

  const customStyles = {
    border: "2px solid black",
    fontWeight: "bold",
  };

  useEffect(() => {
    if (searchValue && searchButton) {
      setLoading(true);
      addMember()
        .getArrivalsDeparturesCummulativeCount(searchValue)
        .then((res) => {
          setUnitMemberCounts(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  }, [searchValue, searchButton]);

  useEffect(() => {
    if (reportTitle !== "") window.print();
  }, [reportTitle]);

  const handleReportTitle = () => {
    setModal(true);
  };

  const excelDownload = () => {
    const modal ={
      arrivalsDeparturesCounts: unitMemberCounts,
      startDate: convertToDateString(searchValue.startDate),
      endDate: convertToDateString(searchValue.endDate),
    }
    addMember()
    .generateArrivalsDeparturesCummulative(modal)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "ArrivalsDeparturesCummulative.xlsx");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {modal ? (
        <SetReportTitle setModal={setModal} setReportTitle={setReportTitle} />
      ) : null}
      <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
        <SearchArrivalsDeparturesCummulative setSearchButton={setSearchButton} searchValue={searchValue} setSearchValue={setSearchValue} />
        {searchButton ? (
          <div className="ibox">
            <div className="ibox-title">
              <h3>
                <GetLanguageString
                  props="quick_reports5_title"
                  value={moment(searchValue.startDate).format("DD/MM/yyyy")}
                />{" "}
                {moment(searchValue.endDate).format("DD/MM/yyyy")})
              </h3>
              <ButtonGroup className={GetCustomStyles().err_btn_group_style}>
                <Button color="default" size="sm" onClick={excelDownload}>
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  {<GetLanguageString props="member_report_export_excel" />}
                </Button>
                <Button color="default" size="sm" onClick={handleReportTitle}>
                  <FcPrint size={15} className={GetCustomStyles().fa_style} />
                  {<GetLanguageString props="common_print" />}
                </Button>
              </ButtonGroup>
            </div>
            <div className="ibox-content ibox-center" id="section-to-print">
              <Row className="print">
                <div className="text-center">
                  <h3>Title : {reportTitle}</h3>
                </div>
              </Row>
              {!loading ? (
                <>
                  <Table className="table-bordered m-b-none">
                    <thead>
                      <tr>
                        <th style={{ paddingTop: '30px', border: '2px solid black', borderBottom: '1px solid black' }} rowSpan={2}>#</th>
                        <th style={{ paddingTop: '30px', border: '2px solid black', borderBottom: '1px solid black' }}  rowSpan={2}>
                          {
                            <h4><b><GetLanguageString props="quick_reports3_unit_name" /></b></h4>
                          }
                        </th>
                        <th style={{ borderTop: '2px solid black', borderRight: '2px solid black', borderBottom: '1px solid black'}} className="text-center" colSpan={3}>{<h4><b> <GetLanguageString props="quick_reports5_arrival" /> </b></h4>}</th>
                        <th style={{ borderTop: '2px solid black', borderRight: '2px solid black', borderBottom: '1px solid black'}} className="text-center" colSpan={6}>{<h4><b> <GetLanguageString props="quick_reports5_departures" /></b></h4>}</th>
                      </tr>
                      <tr>
                      <th style={{ border: '1px solid black'}} className="text-center">
                          {<GetLanguageString props="quick_reports5_new_rufqa" />}
                        </th>
                        <th style={{ border: '1px solid black'}} className="text-center">
                          {
                            <GetLanguageString props="quick_reports5_transferred_from_unit" />
                          }
                        </th>
                        <th style={{ borderRight: '2px solid black'}} className="text-center">
                          {<GetLanguageString props="quick_reports5_total" />}
                        </th>
                        <th style={{ border: '1px solid black'}} className="text-center">
                          {
                            <GetLanguageString props="quick_reports5_ikhraj" />
                          }
                        </th>
                        <th style={{ border: '1px solid black'}} className="text-center">
                          {
                            <GetLanguageString props="quick_reports5_mazrat" />
                          }
                        </th>
                        <th style={{ border: '1px solid black'}} className="text-center">
                          {
                            <GetLanguageString props="quick_reports5_faut_shudah" />
                          }
                        </th>
                        <th style={{ border: '1px solid black'}} className="text-center">
                          {
                            <GetLanguageString props="quick_reports5_mafqood_ul_khabar" />
                          }
                        </th>
                        <th style={{ border: '1px solid black'}} className="text-center">
                          {
                            <GetLanguageString props="quick_reports5_transferred_to_unit" />
                          }
                        </th>
                        <th style={{ borderRight: '2px solid black'}} className="text-center">
                          {
                            <GetLanguageString props="quick_reports5_total" />
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {unitMemberCounts?.map((record, index) => {
                        totalCount.departuresCount += record.departuresCount;
                        totalCount.arrivalsCount += record.arrivalsCount;
                        totalCount.transferredFromMemberCount +=
                          record.transferredFromMemberCount;
                        totalCount.ikhrajCount += record.ikhrajCount;
                        totalCount.mazratCount += record.mazratCount;
                        totalCount.fautShudahCount += record.fautShudahCount;
                        totalCount.mafqoodUlKhabarCount += record.mafqoodUlKhabarCount;
                        totalCount.newMemberCount += record.newMemberCount;
                        totalCount.transferredToMemberCount += record.transferredToMemberCount;
                        return (
                          <tr key={index}>
                            <td style={{ borderRight: '2px solid black', borderLeft: '2px solid black'}}>{index + 1}</td>
                            <td style={{ borderRight: '2px solid black'}} className="ur">{record.name}</td>
                            <td className="text-center">
                              {record.newMemberCount}
                            </td>
                            <td className="text-center">
                              {record.transferredToMemberCount}
                            </td>
                            <td style={{ borderRight: '2px solid black'}} className="text-center">
                              {record.arrivalsCount}
                            </td>
                            <td className="text-center">
                              {record.ikhrajCount}
                            </td>
                            <td className="text-center">
                              {record.mazratCount}
                            </td>
                            <td className="text-center">
                              {record.fautShudahCount}
                            </td>
                            <td className="text-center">
                              {record.mafqoodUlKhabarCount}
                            </td>
                            <td className="text-center">
                              {record.transferredFromMemberCount}
                            </td>
                            <td style={{ borderRight: '2px solid black'}} className="text-center">
                              {record.departuresCount}
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td style={customStyles}></td>
                        <td style={customStyles}></td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.newMemberCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.transferredToMemberCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.arrivalsCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.ikhrajCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.mazratCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.fautShudahCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.mafqoodUlKhabarCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.transferredFromMemberCount}
                        </td>
                        <td className="text-center" style={customStyles}>
                          {totalCount.departuresCount}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              ) : (
                LoadingSpinner()
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ArrivalsDeparturesCummulative;
