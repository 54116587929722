import axios from "axios";

import { BASEURL } from "../../constants/const";
import { encryptStorage } from "../../constants/EncryptStorage";

export function ApiCourse() {
  const userToken = encryptStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

  const fileAuthAxios = axios.create({
    baseURL: BASEURL,
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    responseType: "arraybuffer",
  });

  return {
    get: (searchCourseModel) =>
      authAxios.post(BASEURL + "course/getList/", searchCourseModel),
    checkCanAddCourse: () =>
      authAxios.get(BASEURL + "course/checkCanAddCourse/"),
    getCourseReport: (searchCourseModel) =>
      authAxios.post(BASEURL + "course/getCourseReport/", searchCourseModel),
    GetCourseParticipantsReport: (searchCourseModel) =>
      authAxios.post(
        BASEURL + "course/GetCourseParticipantsReport/",
        searchCourseModel
      ),
    SearchCourseParticipants: (name, type, courseId) =>
      authAxios.get(
        BASEURL +
          "courseparticipant/searchCourseParticipants/" +
          name +
          "/" +
          type +
          "/" +
          courseId
      ),
    getCourseParticipantsReportAttendance: (reportSearchMemberModel) =>
      authAxios.post(
        BASEURL + "courseparticipant/getCourseParticipantsReport/",
        reportSearchMemberModel
      ),
      GenerateCourseParticipantsReport: (model) =>
      fileAuthAxios.post(
        BASEURL + "courseparticipant/GenerateCourseParticipantsReport/",
        model
      ),
    GetCourseParticipantsExcelReport: (searchCourseModel) =>
      fileAuthAxios.post(
        BASEURL + "course/GetCourseParticipantsExcelReport/",
        searchCourseModel
      ),
    deleteCourseParticipant: (memberId, courseId) =>
      authAxios.delete(
        BASEURL + "courseparticipant/delete/" + memberId + "/" + courseId
      ),
    addMembersbyRegNo: (record) =>
      authAxios.post(BASEURL + "courseparticipant/addbulk", record),
    updateCourseParticipant: (record) =>
      authAxios.put(
        BASEURL + "courseparticipant/updateCourseParticipant",
        record
      ),
    getAddCourseList: () =>
      authAxios.put(BASEURL + "coursetype/getAddCourseList"),
    getCoursesLocations: () =>
      authAxios.get(BASEURL + "coursetype/getCoursesLocations"),
    ChangeCourseStatus: (courseId, flag) =>
      authAxios.put(
        BASEURL + "course/ChangeCourseStatus/" + courseId + "/" + flag
      ),
    getCourseParticipants: (courseId, loggedInUnitId) =>
      authAxios.get(
        BASEURL +
          "courseparticipant/getCourseParticipants/" +
          courseId +
          "/" +
          loggedInUnitId
      ),
    SendCourseAttendeesListNotification: (courseId) =>
      authAxios.get(
        BASEURL + "course/SendCourseAttendeesListNotification/" + courseId
      ),

    GetCourseAttendeesListForNotificationData: (
      courseId,
      unitId,
      responsibilityId
    ) =>
      authAxios.get(
        BASEURL +
          "course/GetCourseAttendeesListForNotificationData/" +
          courseId +
          "/" +
          unitId +
          "/" +
          responsibilityId
      ),

    addCourseLocation: (location) =>
      authAxios.put(BASEURL + "coursetype/AddCourseLocation/" + location),
    editCourseLocation: (id, location) =>
      authAxios.put(
        BASEURL + "coursetype/UpdateCourseLocation/" + id + "/" + location
      ),
    deleteCourseLocation: (id) =>
      authAxios.delete(BASEURL + "coursetype/DeleteCourseLocation/" + id),
  };
}
