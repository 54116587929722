import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/ur";
import { Table } from "reactstrap";
import headerImage from "../../content/css/img/printHeader.png";
import { applyClassToCharacters } from "../../constants/const";

const PrintCourseAttendanceNotification = ({ printData }) => {
  moment.locale("ur");
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (!imageLoaded) {
      const img = new Image();
      img.src = headerImage;
      img.onload = () => setImageLoaded(true);
    }
  }, [imageLoaded]);

  const containerStyle = {
    width: "100%",
    top: -200,
    padding: "0.15in 0.4in",
    background: "#fff",
    color: "#000",
    fontSize: "17px",
  };

  const textStyle = { fontSize: "15px" };
  const tableCellStyle = { border: "1px solid black", fontSize: "12px" };

  return (
    <>
      <div id="section-to-print" style={containerStyle} className="print">
        {/* Header Section */}
        <div className="text-center">
          <img
            src={headerImage}
            alt="Tanzeem Islami Header"
            style={{ maxWidth: "100%" }}
          />
        </div>

        {/* Content Section */}
        <div className="text-end ur" style={{ direction: "rtl" }}>
          {/* Date */}
          <p className="text-start mb-0" style={{ ...textStyle }}>
            <u>{moment(printData.notificationDate).format("LL")}</u>
          </p>

          {/* Recipient Information */}
          <p className="mb-2" style={textStyle}>
            جناب {printData.unitInchargeName}،{" "}
            {printData.unitInchargeResponsibility} {printData.unitName}
          </p>

          {/* Greeting */}
          <p className="mb-3" style={textStyle}>
            السلام علیکم ورحمۃ اللہ وبرکاتہ
          </p>

          {/* Subject */}
          <p className="mb-3" style={textStyle}>
            <strong>عنوان:</strong>{" "}
            <span style={{ marginRight: "10px", marginleft: "10px" }}>
              {printData.courseName}
            </span>
            <span style={{ marginRight: "10px", marginleft: "10px" }}>
              {" "}
              منعقدہ{" "}
            </span>
            <span style={{ marginRight: "10px", marginleft: "10px" }}>
              {moment(printData.startDate).format("LL")}
            </span>{" "}
            <span style={{ marginRight: "5px", marginleft: "5px" }}> تا </span>{" "}
            <span style={{ marginRight: "10px", marginleft: "10px" }}>
              {moment(printData.endDate).format("LL")}
            </span>{" "}
            <span style={{ marginRight: "5px", marginleft: "5px" }}>
              {" "}
              بمقام{" "}
            </span>{" "}
            <span style={{ marginRight: "10px", marginleft: "10px" }}>
              {printData.courseLocation}
            </span>{" "}
          </p>

          {/* Main Body */}
          <p className="mb-3" style={textStyle}>
            مذکورہ بالا اجتماع میں آپ{" "}
            {printData.unitType.includes("زون") ||
            printData.unitType.includes("مقامی تنظیم")
              ? "کی"
              : "کے"}{" "}
            {printData.unitType} سے درج ذیل رفقاء نے شرکت کی جن کی فہرست ذیل میں
            درج ہے۔
          </p>
          <p className="mb-3" style={textStyle}>
            گزارش ہے کہ اس فہرست میں کوئی غلطی ہو تو توصیح کر کے زیادہ سے زیادہ
            ایک ماہ میں مرکز تنظیم اسلامی مطلع فرما دیجئے۔ بصورت دیگر اس فہرست
            کو درست تسلیم کر کے متعلقہ کاروائی کر لی جائے گی۔ آپ سے عرض ہے کے
            کیفیت کی اطلاع شرکاء تک پہنچانے اور اس کے مطابق عملی اقدامات کا
            اہتمام کروا دیجئے۔
          </p>
          <p className="mb-3" style={textStyle}>
            اللہ تعالی ہم سب کا حامی و ناصر ہو۔
          </p>

          {printData.courseName.includes("مبتدی تربیتی") ? (
            <div className="row">
              <p>
                <u>قابل غور امور : </u>
              </p>

              <p className="mb-3" style={textStyle}>
                جن شرکاء کی کیفیت{" "}
                <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                  {" "}
                  نامکمل{" "}
                </span>{" "}
                لکھا ہوا ہے انہیں ایک سال کے دورانیے میں کورس کی تکمیل کرنا
                ہوگی.
              </p>
            </div>
          ) : null}
          <br />
          <div className="table-responsive">
            <Table
              bordered
              className="text-center"
              style={{ border: "1px solid black" }}
            >
              <thead>
                <tr>
                  <th style={tableCellStyle}>#</th>
                  <th style={tableCellStyle}>نام</th>
                  <th style={tableCellStyle}>ولدیت</th>
                  <th style={tableCellStyle}>رجسٹریشن نمبر</th>
                  <th style={tableCellStyle}>نظم</th>
                  <th style={tableCellStyle}>موبائل</th>
                  <th style={tableCellStyle}>کیفیت</th>
                  <th style={tableCellStyle}>ریمارکس</th>
                </tr>
              </thead>
              <tbody>
                {printData.attendeesList.map((row, index) => (
                  <tr key={index}>
                    <td style={tableCellStyle}>{index + 1}</td>
                    <td style={tableCellStyle}>
                      {applyClassToCharacters(row.name, "ur")}
                    </td>
                    <td style={tableCellStyle}>{row.fatherName}</td>
                    <td style={{ ...tableCellStyle, direction: "ltr" }}>
                      {applyClassToCharacters(row.registrationNumber, "ur")}
                    </td>
                    <td style={tableCellStyle}>{row.unitName}</td>
                    <td style={tableCellStyle}>{row.mobileNumber}</td>
                    <td style={tableCellStyle}>
                      {!row.complete ? (
                        "مکمل"
                      ) : (
                        <span style={{ fontWeight: "bold", fontSize: 13 }}>
                          نامکمل
                        </span>
                      )}
                    </td>
                    <td style={{ ...tableCellStyle, maxWidth: 120 }}>
                      {row.remarks}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintCourseAttendanceNotification;
