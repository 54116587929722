import React, { useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import NotificationAlerts from "./NotificationAlerts";
import Notifications from "./Notifications";
import { GetLanguageString } from "../../helper/Components";

const NotificationsTabs = () => {
  const [activeTab, setActiveTab] = useState("requests");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="tabs-container">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "requests" ? "active" : ""}
              onClick={() => toggle("requests")}
              style={{ cursor: "pointer" }}
            >
              <GetLanguageString props="notifications_requests" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "alerts" ? "active" : ""}
              onClick={() => toggle("alerts")}
              style={{ cursor: "pointer" }}
            >
              <GetLanguageString props="notifications_alerts" />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="requests">
            <div className="panel-body">
              <Notifications activeTab={activeTab} />
            </div>
          </TabPane>
          <TabPane tabId="alerts">
            <div className="panel-body">
              <NotificationAlerts activeTab={activeTab} />
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default withRouter(NotificationsTabs);
