import React, { useCallback, useEffect, useRef, useState } from "react";
import { addMember } from "../ApiMember";
import { LoadingSpinner, isUrdu } from "../../../constants/const";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { GetLanguageString } from "../../helper/Components";
import swal from "sweetalert";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { GetSelectedLanguage } from "../../helper/Method";
import Draggable from "react-draggable";
import imageCompression from "browser-image-compression";

const AddNewDocument = ({
  setAddDocumentModal,
  memberId,
  setRefreshList,
  refreshList,
}) => {
  const fileInputRef = useRef(null);
  const toggle = () => setAddDocumentModal(false);
  const [loading, setLoading] = useState(false);
  const [documentsTypesDropdown, setDocumentsTypesDropdown] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [pledgesDropdown, setPledgesDropdown] = useState([]);
  const [errorFile, setErrorFile] = useState();
  const [file, setFile] = useState("");
  const [addDocumentValues, setAddDocumentValues] = useState({
    file: "",
    description: "",
    memberId: memberId,
    documentType: "",
    pledge: "",
  });
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };
  const [errors, setErrors] = useState();

  const { t } = useTranslation();

  const changeDocumentTypesLanguage = useCallback(
    (documentsTypes) => {
      const translatedDocumentTypes = documentsTypes.map((item) => {
        return {
          label: t(item.label),
          value: item.value,
          isDisabled: item.isDisabled,
          isSelected: item.isSelected,
          label1: item.label1,
          IsDeletable: item.IsDeletable,
        };
      });

      setDocumentsTypesDropdown(translatedDocumentTypes);
    },
    [t]
  );

  useEffect(() => {
    setLoading(true);
    addMember()
      .GetAddDocumentTypeDropdowns(memberId)
      .then((res) => {
        changeDocumentTypesLanguage(res.data.documentsTypes);
        setAddDocumentValues({
          ...addDocumentValues,
          documentType: res.data.documentsTypes[0],
        });
        if (res.data.documentsTypes.length === 0) checkCanAdd();
        setLoading(false);
      })
      .catch((err) => {
        swal({
          title: err.response?.data || err,
          icon: "error",
          buttons: "OK",
        });
        setLoading(false);
        console.log(err.response?.data || err);
      });
    // eslint-disable-next-line
  }, [memberId, changeDocumentTypesLanguage]);

  useEffect(() => {
    if (addDocumentValues.documentType) {
      addMember()
        .GetAddPledgesDropdowns(memberId, addDocumentValues.documentType.value)
        .then((res) => {
          setPledgesDropdown(res.data);
        })
        .catch((err) => {
          swal({
            title: err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err.response?.data || err);
        });
    }
  }, [memberId, addDocumentValues.documentType]);

  const checkCanAdd = () => {
    if (documentsTypesDropdown.length === 0) {
      swal({
        title: t("member_member_detail_cant_add_documents"),
        icon: "error",
        className: GetSelectedLanguage() === "en" ? "" : "ur",
        buttons: "OK",
      }).then(() => {
        toggle();
      });
    }
  };

  const handleUpload = async (e) => {
    try {
      const selectedFile = e.target.files[0];

      if (selectedFile && selectedFile.size < 10 * 1024 * 1024) {
        const options = {
          maxSizeMB: 10,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        let compressedFile;
        if (!selectedFile.type.includes("pdf")) {
          compressedFile = await imageCompression(selectedFile, options);
        }

        setFile(compressedFile !== undefined ? compressedFile : selectedFile);
        setErrorFile("");

        setErrors({
          ...errors,
          file: "",
        });
      } else {
        setFile(null);
        setErrorFile(
          selectedFile?.size > 10 * 1024 * 1024 ? (
            <GetLanguageString props="member_add_file_upload_size_error" />
          ) : (
            <GetLanguageString props="member_add_file_upload_error" />
          )
        );
      }
    } catch (error) {
      console.error("Error compressing image:", error);
    }
  };

  const validate = () => {
    let temp = {};
    temp.file =
      file === "" ? (
        <GetLanguageString props="member_member_detail_upload_documents_error" />
      ) : (
        ""
      );
    temp.documentType =
      addDocumentValues.documentType === "" ? (
        <GetLanguageString props="member_member_detail_select_documents_type_error" />
      ) : (
        ""
      );
    temp.pledge =
      addDocumentValues.pledge === "" &&
      addDocumentValues.documentType.label !== "Kawaif Form" ? (
        <GetLanguageString props="member_member_detail_select_pledges_type_error" />
      ) : (
        ""
      );

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleChangeDocumentType = (record) => {
    setAddDocumentValues({
      ...addDocumentValues,
      documentType: record,
      pledge: "",
    });
    setErrors({
      ...errors,
      documentType: "",
    });
    if (!record.label.includes("Kawaif")) {
      setFile("");
      fileInputRef.current.value = "";
    }
  };

  const handleChangePledge = (record) => {
    setAddDocumentValues({
      ...addDocumentValues,
      pledge: record,
    });
    setErrors({
      ...errors,
      pledge: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddDocumentValues({
      ...addDocumentValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const files = new FormData();
    files.append("File", file);
    if (validate() && errorFile === "") {
      setLoading(true);
      setButtonDisable(true);
      addMember()
        .GetFileToSave(
          memberId,
          addDocumentValues.documentType.value,
          addDocumentValues.pledge.value,
          files
        )
        .then((res) => {
          addDocumentValues.file = res.data;
          addMember()
            .AddNewMemberDocuments(addDocumentValues)
            .then((res) => {
              swal({
                title: t("member_add_file_uploaded"),
                icon: "success",
                className: GetSelectedLanguage() === "en" ? "" : "ur",
                buttons: "OK",
              });
              setLoading(false);
              setButtonDisable(false);
              setRefreshList(!refreshList);
              toggle();
            })
            .catch((err) => {
              console.log(err);
              swal({
                title: err,
                icon: "error",
                buttons: "OK",
              });
              setLoading(false);
              setButtonDisable(false);
            });
        })
        .catch((err) => {
          setLoading(false);
          swal({
            title: err,
            icon: "error",
            buttons: "OK",
          });
          toggle();
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="member_member_detail_documents_add" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label className="col-form-label">
                    {" "}
                    {
                      <GetLanguageString props="member_member_detail_select_documents_type" />
                    }
                  </Label>
                  <Select
                    value={addDocumentValues.documentType}
                    options={documentsTypesDropdown}
                    className={
                      GetSelectedLanguage() === "ur"
                        ? "basic-multi-select ur no-drag"
                        : "basic-multi-select no-drag"
                    }
                    classNamePrefix="select"
                    onChange={(record) => {
                      handleChangeDocumentType(record);
                    }}
                  />
                  {errors?.documentType && (
                    <div className="text-error">{errors.documentType}</div>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="member_member_detail_upload_documents" />
                    }
                  </Label>
                  <Input
                    ref={fileInputRef}
                    type="file"
                    accept={
                      addDocumentValues.documentType?.label?.includes("Kawaif")
                        ? "image/*,application/pdf"
                        : "image/*"
                    }
                    name="baitFormFront"
                    maxSize={10 * 1024 * 1024}
                    onChange={handleUpload}
                  />
                  {errorFile && <div className="text-error">{errorFile}</div>}
                  {errors?.file && (
                    <div className="text-error">{errors.file}</div>
                  )}
                </FormGroup>
                {addDocumentValues.documentType?.label !== "Kawaif Form" ? (
                  <FormGroup>
                    <Label className="col-form-label">
                      {" "}
                      {
                        <GetLanguageString props="member_member_detail_select_pledges_type" />
                      }
                    </Label>
                    <Select
                      value={addDocumentValues.pledge}
                      options={pledgesDropdown}
                      className="basic-multi-select ur no-drag"
                      classNamePrefix="select"
                      isDisabled={
                        pledgesDropdown && pledgesDropdown.length > 0
                          ? false
                          : true
                      }
                      onChange={(record) => {
                        handleChangePledge(record);
                      }}
                    />
                    {errors?.pledge && (
                      <div className="text-error">{errors.pledge}</div>
                    )}
                  </FormGroup>
                ) : null}
                <FormGroup>
                  <Label>
                    {<GetLanguageString props="common_description" />}
                  </Label>
                  <Input
                    type="text"
                    name="description"
                    className={
                      isUrdu(addDocumentValues.description)
                        ? "ur no-drag"
                        : "no-drag"
                    }
                    value={addDocumentValues.description}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" disabled={buttonDisable} type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddNewDocument;
