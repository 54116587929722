import React, { useState, useEffect } from "react";
import Select from "react-select";

import {
  Button,
  ButtonGroup,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
} from "reactstrap";

import useForm from "../../../shared/useForm";
import { ApiUnit } from "../../../unit/ApiUnit";
import { GetLanguageString } from "../../../helper/Components";
import { encryptStorage } from "../../../../constants/EncryptStorage";
import FundFilter from "../../../helper/SearchFilter/FundFilter";
import { convertToDateString } from "../../../../constants/const";

const SearchArrivalsDeparturesCummulative = ({
  setSearchButton,
  searchValue,
  setSearchValue
}) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [unitType, setUnitType] = useState(
    encryptStorage.getItem("unitTypeName")
  );
  const [showAllUnits, setShowAllUnits] = useState("primary");
  const [showAllZones, setShowAllZones] = useState("white");
  const [showAllHalqas, setShowAllHalqas] = useState("white");
  const [showAllTanzeem, setShowAllTanzeem] = useState("white");
  const [showAllUsra, setShowAllUsra] = useState("white");
  const unitTypeName = "null";

  const [unitList, setUnitList] = useState([]);
  const { values, setValues } = useForm(searchValue);


  useEffect(() => {
    ApiUnit()
      .getUnitList(
        loggedInUnitId,
        loggedInUnitId,
        false,
        unitTypeName,
        excludeExUnit
      )
      .then((res) => {
        setUnitList(res.data);
      })
      .catch((err) => console.log(err));
  }, [loggedInUnitId, excludeExUnit]);

  const handleChangeUnits = (record) => {
    if (record !== null) {
      ApiUnit()
        .getUnitList(
          record.value,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: record.value,
        showSelectedUnitsCheck: "None",
      });
      setShowAllZones("white");
      setShowAllUnits("primary");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setUnitType(record.label1);
    } else {
      ApiUnit()
        .getUnitList(
          loggedInUnitId,
          loggedInUnitId,
          false,
          unitTypeName,
          excludeExUnit
        )
        .then((res) => {
          setUnitList(res.data);
        })
        .catch((err) => console.log(err));
      setValues({
        ...values,
        unitId: 0,
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchValue(values);
    if (values.startDate && values.endDate) {
      setSearchValue({
          ...values,
          startDate: convertToDateString(values.startDate),
          endDate: convertToDateString(values.endDate),
        });
        setSearchButton(true);
      }
  };

  const handleShowSelectedUnitsCheck = (e) => {
    const { value } = e.target;
    if (value === "0") {
      setShowAllZones("white");
      setShowAllUnits("primary");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "None",
      });
    } else if (value === "1") {
      setShowAllZones("primary");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Zone",
      });
    } else if (value === "2") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("primary");
      setShowAllTanzeem("white");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Halqa",
      });
    } else if (value === "3") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("primary");
      setShowAllUsra("white");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Tanzeem",
      });
    } else if (value === "4") {
      setShowAllZones("white");
      setShowAllUnits("white");
      setShowAllHalqas("white");
      setShowAllTanzeem("white");
      setShowAllUsra("primary");
      setValues({
        ...values,
        showSelectedUnitsCheck: "Usra",
      });
    }
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
    setValues({
      ...values,
      excludeExUnit: v,
    });
  };

  const handleIncludeDisabledUnit = (e) => {
    const { value } = e.target;
    let v = value;
    console.log(value);
    if (value === "true") v = true;
    else v = false;
    setValues({
      ...values,
      includeDisabledUnit: v,
    });
  };

  return (
    <div className="ibox noprint">
      <div className="ibox-title">
        <h5>{<GetLanguageString props="common_generate_report" />}</h5>
      </div>
      <div className="ibox-content">
        <Form onSubmit={handleSubmit}>
          <FundFilter values={values} setValues={setValues} showFullDate={true} />
          <Row>
            {unitList.map((record, index) => {
              const unitOptions = [];
              record.unitList.forEach((d) =>
                unitOptions.push({
                  value: d.id,
                  label: d.name,
                  label1: d.unitTypeName,
                })
              );
              let selected = {};
              let unitName = "";
              if (record.parentUnitName !== null)
                unitName = record.parentUnitName;
              if (record.selectedUnit !== null) {
                selected = {
                  value: record.selectedUnit.id,
                  label: record.selectedUnit.name,
                };
              }

              return (
                <Col md="2" key={index}>
                  <FormGroup>
                    <Label className="col-form-label">
                      {unitName !== "" ? (
                        <GetLanguageString
                          props="member_select_unit_name"
                          value={unitName}
                        />
                      ) : (
                        <GetLanguageString props="member_select_unit" />
                      )}
                    </Label>
                    <Select
                      options={unitOptions}
                      value={selected}
                      className="basic-single ur"
                      classNamePrefix="select"
                      onChange={handleChangeUnits}
                    />
                  </FormGroup>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col md="4" style={{ paddingTop: 15 }}>
              <FormGroup check>
                <Label check for="excludeExUnit">
                  <Input
                    id="excludeExUnit"
                    type="checkbox"
                    name="excludeExUnit"
                    checked={!excludeExUnit}
                    value={!excludeExUnit}
                    onChange={handleExcludeExUnit}
                  />
                  {
                    <GetLanguageString props="member_search_member_ex_unit" />
                  }
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <ButtonGroup
                style={{ paddingTop: 25 }}
                className="text-center"
              >
                {(unitType === "Markaz" ||
                  unitType === "Zone" ||
                  unitType === "Halqa") && (
                  <Button
                    color={showAllUnits}
                    value={0}
                    onClick={handleShowSelectedUnitsCheck}
                  >
                    {
                      <GetLanguageString props="report_member_show_all_units" />
                    }
                  </Button>
                )}
                {unitType === "Markaz" && (
                  <Button
                    color={showAllZones}
                    value={1}
                    onClick={handleShowSelectedUnitsCheck}
                  >
                    {<GetLanguageString props="report_member_show_zones" />}
                  </Button>
                )}
                {(unitType === "Markaz" || unitType === "Zone") && (
                  <Button
                    color={showAllHalqas}
                    value={2}
                    onClick={handleShowSelectedUnitsCheck}
                  >
                    {<GetLanguageString props="report_member_show_halqa" />}
                  </Button>
                )}
                {(unitType === "Markaz" ||
                  unitType === "Zone" ||
                  unitType === "Halqa") && (
                  <Button
                    color={showAllTanzeem}
                    value={3}
                    onClick={handleShowSelectedUnitsCheck}
                  >
                    {
                      <GetLanguageString props="report_member_show_tanzeem" />
                    }
                  </Button>
                )}
                {(unitType === "Markaz" ||
                  unitType === "Zone" ||
                  unitType === "Halqa") && (
                  <Button
                    color={showAllUsra}
                    value={4}
                    onClick={handleShowSelectedUnitsCheck}
                  >
                    {<GetLanguageString props="report_member_show_usra" />}
                  </Button>
                )}
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col md="4" style={{ paddingTop: 25 }}>
              <FormGroup check>
                <Label check for="includeDisabledUnit">
                  <Input
                    id="includeDisabledUnit"
                    type="checkbox"
                    name="includeDisabledUnit"  
                    value={!values.includeDisabledUnit}
                    onChange={handleIncludeDisabledUnit}
                  />
                  {
                    <GetLanguageString props="member_search_disabled_unit" />
                  }
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row className="m-t-md">
            <Col className="text-center">
              <FormGroup>
                <Button color="primary" className="m-l-sm" type="submit">
                  {<GetLanguageString props="common_view_report" />}
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default SearchArrivalsDeparturesCummulative;
