import { saveAs } from "file-saver";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { RiFileExcel2Line } from "react-icons/ri";
import { TbCopy } from "react-icons/tb";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import {
  Button,
  ButtonGroup,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "reactstrap";
import swal from "sweetalert";
import {
  convertUTCToLocalTime,
  isUrdu,
  LoadingSpinner,
} from "../../../constants/const";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { GetLanguageString, NoResult } from "../../helper/Components";
import {
  DateValidater,
  GetCustomStyles,
  GetFooTableCustomStyles,
  GetSelectedLanguage,
} from "../../helper/Method";
import { ApiUnit } from "../../unit/ApiUnit";
import { ApiNotifications } from "../ApiNotifications";
import NotificationAction from "../NotificationAction";
import PrintNotification from "../PrintNotification";
import SeeChanges from "../SeeChanges";

const Notifications = ({ activeTab }) => {
  const notify = (string) => toast.success(string);
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({ activePage: 1 });
  const responsibilityId = encryptStorage.getItem("responsibilityId");
  const loggedInUnitId = encryptStorage.getItem("loggedInUnitId");
  const [loading, setLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [values, setValues] = useState(false);
  const [notification, setNotification] = useState([]);
  const [notificationName, setNotificationName] = useState();
  const [notificationId, setNotificationId] = useState();
  const [transferNotificationUnitId, setTransferNotificationUnitId] =
    useState();
  const [serialNumber, setSerialNumber] = useState();
  const [isSerialNumberCancelled, setIsSerialNumberCancelled] = useState();
  const [searchSerialNumber, setSearchSerialNumber] = useState();
  const [searchName, setSearchName] = useState("");
  const [documents, setDocuments] = useState();

  const [tooltipOpen, setTooltipOpen] = useState(null);
  const [tooltipOpenCopy, setTooltipOpenCopy] = useState(null);

  const toggleTooltip = (index) => {
    setTooltipOpen((prevOpen) => (prevOpen === index ? null : index));
  };
  const toggleTooltipCopy = (index) => {
    setTooltipOpenCopy((prevOpen) => (prevOpen === index ? null : index));
  };

  const [printType, setPrintType] = useState(0);
  const [notificationTypes, setNotificationTypeFilter] = useState([]);
  const [selectedType, setSelectedType] = useState({ value: 0, label: "All" });
  const [approvalStatuses, setApprovalStatusesFilter] = useState([]);
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState({
    value: -1,
    label: "All",
  });
  const [relevantUnitsList, setRelevantUnitsList] = useState([]);
  const [selectedRelevantUnit, setSelectedRelevantUnit] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dateRangeValidation = {
    startDate: null,
    endDate: null,
  };
  const [dateValidations, setDateValidations] = useState(dateRangeValidation);

  const [units, setUnitsFilter] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState({
    value: "0-0",
    label: "تمام",
  });

  const copyToClipboard = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        notify(t("notifications_comments_copied"));
      })
      .catch((err) => {
        notify("Failed to copy: ", err);
      });
  };

  const [record, setRecord] = useState({
    responsibilityId: responsibilityId,
    loggedInUnitId: loggedInUnitId,
    notificationTypeId: selectedType?.value,
    approvalStatus: selectedApprovalStatus?.value,
    relevantUnit: selectedRelevantUnit?.value,
    serialNumber: searchSerialNumber,
    startDate: startDate ?? Date.minDate,
    endDate: endDate,
    notificationUnitId: parseInt(selectedUnit?.value.split("-")[0]),
    notificationResponsibilityId: parseInt(selectedUnit?.value.split("-")[1]),
    activePage: 1,
    creationDate: "neutral",
    fullName: searchName,
    // pageSize: rows[0].value,
  });

  const [printData, setPrintData] = useState({
    contactNo: "",
    email: "",
    dob: "",
    education: "",
    fatherName: "",
    fullName: "",
    infradiDawat: "",
    jihadBilMaal: "",
    maritalStatus: "",
    mubtadiCourse: {
      startDate: "",
      location: "",
    },
    multazimCourse: {
      startDate: "",
      location: "",
    },
    nisabBaraEMutaliya: "",
    occupation: "",
    newAddress: "",
    newUnitName: "",
    preUnitName: "",
    registranNumber: "",
    responsibilityName: "",
    samaOBasar: "",
    samaOTaat: "",
    taluqMaAllah: "",
    ameerName: "",
    unitName: "",
    otherInfo: "",
  });

  const handlePageChange = (pageNumber) => {
    setRecord({
      ...record,
      responsibilityId: responsibilityId,
      loggedInUnitId: loggedInUnitId,
      notificationTypeId: selectedType?.value,
      approvalStatus: selectedApprovalStatus?.value,
      relevantUnit: selectedRelevantUnit?.value,
      serialNumber: searchSerialNumber,
      startDate: startDate ?? Date.minDate,
      endDate: endDate,
      notificationUnitId: parseInt(selectedUnit?.value.split("-")[0]),
      notificationResponsibilityId: parseInt(selectedUnit?.value.split("-")[1]),
      activePage: pageNumber,
      creationDate: "neutral",
      fullName: searchName,
    });
    setPagination({
      ...pagination,
      activePage: pageNumber,
    });
  };

  const handleSorting = () => {
    if (
      record.creationDate === "neutral" ||
      record.creationDate === "descending"
    ) {
      setRecord({
        ...record,
        creationDate: "ascending",
      });
    } else if (record.creationDate === "ascending") {
      setRecord({
        ...record,
        creationDate: "descending",
      });
    }
  };

  const handleSubmit = () => {
    const record = {
      responsibilityId: responsibilityId,
      loggedInUnitId: loggedInUnitId,
      notificationTypeId: selectedType?.value,
      approvalStatus: selectedApprovalStatus?.value,
      relevantUnit: selectedRelevantUnit?.value,
      serialNumber: searchSerialNumber,
      startDate: startDate ?? Date.minDate,
      endDate: endDate,
      notificationUnitId: parseInt(selectedUnit?.value.split("-")[0]),
      notificationResponsibilityId: parseInt(selectedUnit?.value.split("-")[1]),
      creationDate: "neutral",
      fullName: searchName,
    };

    setLoading(true);
    ApiNotifications()
      .getNotificationList(record)
      .then((res) => {
        setPagination(res.data);
        setNotification(res.data.notificationList);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const excelDownload = () => {
    const record = {
      responsibilityId: responsibilityId,
      loggedInUnitId: loggedInUnitId,
      notificationTypeId: selectedType?.value,
      approvalStatus: selectedApprovalStatus?.value,
      relevantUnit: selectedRelevantUnit?.value,
      serialNumber: searchSerialNumber,
      startDate: startDate ?? Date.minDate,
      endDate: endDate,
      notificationUnitId: parseInt(selectedUnit?.value.split("-")[0]),
      notificationResponsibilityId: parseInt(selectedUnit?.value.split("-")[1]),
      fullName: searchName,
      reportType: "notification",
    };

    setExcelLoading(true);
    ApiNotifications()
      .generateNotificationsReport(record)
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "NotificationRequests.xlsx");
        setExcelLoading(false);
      })
      .catch((err) => {
        setExcelLoading(false);
        console.log(err);
      });
  };

  const printPdf = (id, notificationType) => {
    if (notificationType === "Transfer Rafiq") setPrintType(1);
    else if (notificationType === "Transfer Habib") setPrintType(2);
    else setPrintType(0);

    ApiNotifications()
      .generateNotificationsPdfForm(id, notificationType)
      .then((res) => {
        setPrintData(res.data);
        window.print();
      })
      .catch((err) => console.log(`Error generating PDF: ${err}`));
  };

  useEffect(() => {
    if (activeTab === "requests") {
      setLoading(true);
      ApiNotifications()
        .GetNotificationTypes(activeTab)
        .then((res) => {
          setNotificationTypeFilter(res.data);
        })
        .catch((err) => {
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err.response.data);
          setLoading(false);
        });

      ApiNotifications()
        .GetApprovalStatuses(activeTab)
        .then((res) => {
          setApprovalStatusesFilter(res.data);
        })
        .catch((err) => {
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err.response.data);
          setLoading(false);
        });

      ApiNotifications()
        .GetNotificationRelevantUnits(activeTab)
        .then((res) => {
          setRelevantUnitsList(res.data);
          setSelectedRelevantUnit(res.data[0]);
        })
        .catch((err) => {
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err.response.data);
          setLoading(false);
        });

      ApiUnit()
        .GetUnitsForNotificationFilters(
          responsibilityId,
          loggedInUnitId,
          activeTab
        )
        .then((res) => {
          setUnitsFilter(res.data);
        })
        .catch((err) => {
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err.response.data);
          setLoading(false);
        });
    }
  }, [responsibilityId, loggedInUnitId, activeTab]);

  useEffect(() => {
    if (activeTab === "requests") {
      setLoading(true);
      ApiNotifications()
        .getNotificationList(record)
        .then((res) => {
          setPagination(res.data);
          setNotification(res.data.notificationList);
          setLoading(false);
        })
        .catch((err) => {
          swal({
            title: "Error: " + err.response?.data || err,
            icon: "error",
            buttons: "OK",
          });
          console.log(err.response.data);
          setLoading(false);
        });
    }
  }, [record, responsibilityId, refreshList, loggedInUnitId, activeTab]);

  const handleSelectChangeNotificationType = (event) => {
    setSelectedType(event);
  };

  const handleSelectChangeUnit = (event) => {
    setSelectedUnit(event);
  };

  const handleSelectRelevantUnit = (event) => {
    setSelectedRelevantUnit(event);
  };

  const handleSelectChangeApprovalStatus = (event) => {
    setSelectedApprovalStatus(event);
  };

  const handleSearchSerialNumber = (e) => {
    const { value } = e.target;
    setSearchSerialNumber(value);
  };

  const handleSearchName = (e) => {
    setSearchName(e.target.value);
  };

  const handleInputChangeStartDate = (value) => {
    if (DateValidater(value, endDate)) {
      setStartDate(value);
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        startDate: (
          <GetLanguageString props="common_start_date_validation_message" />
        ),
      });
    }
  };

  const handleInputChangeEndDate = (value) => {
    if (DateValidater(startDate, value)) {
      setEndDate(value);
      setDateValidations(dateRangeValidation);
    } else {
      setDateValidations({
        ...dateValidations,
        endDate: (
          <GetLanguageString props="common_end_date_validation_message" />
        ),
      });
    }
  };

  const toggleDropdownOpen = (index) => {
    let temp = [...notification];
    temp[index].dropDown = !notification[index].dropDown;
    setNotification(temp);
  };

  let recordIndex = pagination.pageSize * (pagination.activePage - 1) + 1;

  let pageInfo = `Displaying ${recordIndex}-${
    recordIndex + pagination.pageSize - 1 < pagination.totalItem
      ? recordIndex + pagination.pageSize - 1
      : pagination.totalItem
  } of ${pagination.totalItem}`;

  return (
    <div>
      <ToastContainer
        autoClose={3000}
        bodyClassName={GetSelectedLanguage()}
        closeOnClick
        pauseOnHover={false}
        draggable
      />
      {modal ? (
        <SeeChanges
          setModal={setModal}
          values={values}
          notificationName={notificationName}
          documentNames={documents}
          notificationId={notificationId}
          serialNumber={serialNumber}
          isSerialNumberCancelled={isSerialNumberCancelled}
        />
      ) : null}
      {actionModal ? (
        <NotificationAction
          notificationName={notificationName}
          setActionModal={setActionModal}
          notificationId={notificationId}
          setRefreshList={setRefreshList}
          refreshList={refreshList}
          unitId={transferNotificationUnitId}
        />
      ) : null}
      <PrintNotification printData={printData} printType={printType} />
      {
        <div className="panel panel-primary noprint">
          <div className="panel-heading">
            <h4>{<GetLanguageString props="common_search" />}</h4>
          </div>
          <div className="panel-body">
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="settings_notification_type" />}
                  </Label>
                  <Select
                    value={selectedType}
                    options={notificationTypes.map((notification) => ({
                      value: notification.id,
                      label: notification.name,
                    }))}
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    onChange={handleSelectChangeNotificationType}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="notifications_approval_status" />
                    }
                  </Label>
                  <Select
                    value={selectedApprovalStatus}
                    options={approvalStatuses.map((approval) => ({
                      value: approval.statusId,
                      label: <GetLanguageString props={approval.code} />,
                    }))}
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={false}
                    onChange={handleSelectChangeApprovalStatus}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="notifications_requested_by" />}
                  </Label>
                  <Select
                    value={selectedUnit}
                    options={units.map((unit) => ({
                      value: unit.id + "-" + unit.responsibilityId,
                      label:
                        unit.id === 0 || unit.responsibilityId === 0
                          ? unit.name
                          : unit.responsibilityTitle + " / حلقہ : " + unit.name,
                    }))}
                    className="basic-single ur"
                    classNamePrefix="select"
                    isClearable={false}
                    onChange={handleSelectChangeUnit}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="notifications_requested_for" />}
                  </Label>
                  <Select
                    value={selectedRelevantUnit}
                    options={relevantUnitsList}
                    className="basic-single ur"
                    classNamePrefix="select"
                    isClearable={false}
                    onChange={handleSelectRelevantUnit}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <div className="ibox-content">
                  <Label className="col-form-label">
                    <h4>
                      {
                        <GetLanguageString props="notifications_creation_date" />
                      }
                    </h4>
                  </Label>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_report_from" />}
                        </Label>
                        <DatePicker
                          value={startDate}
                          showYearDropdown
                          placeholderText="dd/mm/yyyy"
                          className="form-control"
                          selected={startDate}
                          onChange={handleInputChangeStartDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        {dateValidations.startDate && (
                          <h4 className="text-error">
                            {dateValidations.startDate}
                          </h4>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <Label className="col-form-label">
                          {<GetLanguageString props="member_report_to" />}
                        </Label>
                        <DatePicker
                          value={endDate}
                          showYearDropdown
                          placeholderText="dd/mm/yyyy"
                          className="form-control"
                          selected={endDate}
                          onChange={handleInputChangeEndDate}
                          dateFormat="dd/MM/yyyy"
                        />
                        {dateValidations.endDate && (
                          <h4 className="text-error">
                            {dateValidations.endDate}
                          </h4>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label className="col-form-label">
                    {
                      <GetLanguageString props="notifications_see_changes_serial_number" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="searchSerialNumber"
                    value={searchSerialNumber}
                    onChange={handleSearchSerialNumber}
                  />
                </FormGroup>
              </Col>
              <Col md="3">
                <FormGroup>
                  <Label className="col-form-label">
                    {<GetLanguageString props="common_search_by_name" />}
                  </Label>
                  <Input
                    className={isUrdu(searchName) ? "ur" : ""}
                    type="text"
                    name="searchName"
                    value={searchName}
                    onChange={handleSearchName}
                  />
                </FormGroup>
              </Col>
            </Row>
            <br></br>
            <br></br>
            <Row>
              <Col className="text-center">
                <ButtonGroup>
                  <Button
                    color="primary"
                    className="m-l-sm"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {<GetLanguageString props="common_search" />}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </div>
        </div>
      }
      <div className="panel panel-primary noprint">
        <div className="panel-heading">
          <h4>{<GetLanguageString props="notifications_requests" />}</h4>
          {notification.length !== 0 ? (
            <Button
              color="default"
              size="sm"
              style={{
                marginTop: -30,
              }}
              onClick={excelDownload}
              className={GetCustomStyles().btn_style}
            >
              {excelLoading ? (
                <Spinner size="sm" color="dark" />
              ) : (
                <>
                  <RiFileExcel2Line
                    size={15}
                    color="#009440"
                    style={{ marginRight: 3, marginBottom: 1 }}
                  />
                  <span style={{ color: "black" }}>
                    {<GetLanguageString props="member_report_export_excel" />}
                  </span>
                </>
              )}
            </Button>
          ) : null}
        </div>
        <div className="panel-body">
          {!loading ? (
            notification.length !== 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      {
                        <GetLanguageString props="settings_notification_type_name" />
                      }
                    </th>
                    <th>{<GetLanguageString props="common_name" />}</th>
                    <th
                      className="cursor-pointer"
                      onClick={() => handleSorting("creationDate")}
                    >
                      {
                        <GetLanguageString props="notifications_creation_date" />
                      }
                      <span className={GetFooTableCustomStyles().span_style}>
                        {record.creationDate === "neutral" ? (
                          <>
                            <i
                              className={
                                GetFooTableCustomStyles().up_down_style
                              }
                            />
                            <i
                              className={
                                GetFooTableCustomStyles().down_up_style
                              }
                            />
                          </>
                        ) : record.creationDate === "ascending" ? (
                          <i className={GetFooTableCustomStyles().up_style} />
                        ) : (
                          <i className={GetFooTableCustomStyles().down_style} />
                        )}
                      </span>
                    </th>
                    <th>
                      {<GetLanguageString props="notifications_approve_date" />}
                    </th>
                    <th>
                      {<GetLanguageString props="notifications_requested_by" />}
                    </th>
                    <th>
                      {
                        <GetLanguageString props="notifications_requested_for" />
                      }
                    </th>
                    <th>
                      {
                        <GetLanguageString props="notifications_see_changes_serial_number" />
                      }
                    </th>
                    <th>
                      {
                        <GetLanguageString props="notifications_approve_status" />
                      }
                    </th>
                    <th style={{ width: "170px" }}>
                      {
                        <GetLanguageString props="member_member_detail_course_comments" />
                      }
                    </th>
                    <th className="text-center">
                      {<GetLanguageString props="common_action" />}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {notification
                    .sort(function (a, b) {
                      if (a.startDate < b.startDate) return 1;
                      if (a.startDate > b.startDate) return -1;
                      return 0;
                    })
                    .map((record, index) => {
                      let arr = [];
                      let type = record.code;
                      if (record.code.includes("move_member")) {
                        arr = record.seeChanges;
                      } else if (record.code.includes("move_habib")) {
                        arr = record.seeChangesHabib;
                      } else {
                        if (Array.isArray(record.paramsJSON)) {
                          arr = record.paramsJSON;
                        } else {
                          arr = Object.entries(record.paramsJSON).map(
                            ([key, value]) => {
                              return {
                                label: key,
                                value: value,
                              };
                            }
                          );
                        }
                      }

                      let isTextUrdu = isUrdu(record.comments);
                      let maxLength = isTextUrdu ? 35 : 30;

                      return (
                        <tr key={index}>
                          <td>{index + recordIndex}</td>
                          <td>{record.notificationTypeName}</td>
                          <td
                            className={
                              isUrdu(
                                record.code.includes("new_rafiq")
                                  ? record.paramsJSON.FullName
                                  : arr.fullName
                              )
                                ? "ur"
                                : ""
                            }
                          >
                            {record.code.includes("new_rafiq")
                              ? record.paramsJSON.FullName
                              : arr.fullName}
                          </td>
                          <td>
                            {moment(
                              convertUTCToLocalTime(record.createdAt)
                            ).format("DD/MM/yyyy")}
                          </td>
                          <td>
                            {record.completedAt
                              ? moment(
                                  convertUTCToLocalTime(record.completedAt)
                                ).format("DD/MM/yyyy")
                              : "-"}
                          </td>
                          <td className="ur">
                            {record.responsibilityName.length !== 0 ? (
                              <Label
                                className="label label-primary ur m-1"
                                size="m"
                              >
                                <h5>
                                  {record.responsibilityName +
                                    " / " +
                                    record.unitName}
                                </h5>
                              </Label>
                            ) : (
                              "-"
                            )}
                          </td>
                          <td className="ur">
                            {record.relatedUnits?.length !== 0
                              ? record.relatedUnits?.map((item, index) => {
                                  return (
                                    <>
                                      <Label
                                        className="label label-info ur m-1"
                                        size="sm"
                                      >
                                        <h5>{item.label}</h5>
                                      </Label>
                                    </>
                                  );
                                })
                              : "-"}
                          </td>
                          <td>
                            {record.rafiqSerialNumbers === "0" ||
                            record.rafiqSerialNumbers === "" ||
                            record.rafiqSerialNumbers === null ? (
                              "-"
                            ) : (
                              <>
                                {record.rafiqSerialNumbers}{" "}
                                {record.isSerialNumberCancelled && (
                                  <span
                                    className={
                                      GetSelectedLanguage() === "ur" ? "ur" : ""
                                    }
                                  >
                                    ({t("notifications_cancel")})
                                  </span>
                                )}
                              </>
                            )}
                          </td>
                          <td
                            style={{
                              color:
                                record.approvalStatus === 1
                                  ? "Green"
                                  : record.approvalStatus === 2
                                  ? "Red"
                                  : record.approvalStatus === 4
                                  ? "Red"
                                  : record.approvalStatus === 0
                                  ? "Orange"
                                  : record.approvalStatus === 3
                                  ? "Gold"
                                  : record.approvalStatus === 5
                                  ? "Green"
                                  : record.approvalStatus === 6
                                  ? "Orange"
                                  : null,
                              fontWeight: "bold",
                            }}
                          >
                            {" "}
                            {record.approvalStatus === 1 ? (
                              <GetLanguageString props="notifications_approve" />
                            ) : record.approvalStatus === 2 ? (
                              <GetLanguageString props="notifications_reject" />
                            ) : record.approvalStatus === 4 ? (
                              <GetLanguageString props="notifications_cancel" />
                            ) : record.approvalStatus === 0 ? (
                              <GetLanguageString props="notifications_pending" />
                            ) : record.approvalStatus === 3 ? (
                              <GetLanguageString props="notifications_on_hold" />
                            ) : record.approvalStatus === 5 ? (
                              <GetLanguageString props="notifications_read" />
                            ) : record.approvalStatus === 6 ? (
                              <GetLanguageString props="notifications_unread" />
                            ) : null}
                          </td>
                          <td>
                            {record?.comments ? (
                              <Row>
                                <Col md="10">
                                  <span
                                    className={isTextUrdu ? "ur" : "en"}
                                    id={`tooltip-${index}`}
                                    onMouseEnter={() => toggleTooltip(index)}
                                    onMouseLeave={() => toggleTooltip(index)}
                                  >
                                    {isTextUrdu ? (
                                      <h4
                                        style={
                                          isTextUrdu
                                            ? { lineHeight: "1.8" }
                                            : {}
                                        }
                                      >
                                        {record.comments?.length > maxLength
                                          ? `${record.comments?.slice(
                                              0,
                                              maxLength
                                            )}...`
                                          : record.comments}
                                      </h4>
                                    ) : record.comments?.length > maxLength ? (
                                      `${record.comments?.slice(
                                        0,
                                        maxLength
                                      )}...`
                                    ) : (
                                      record.comments
                                    )}
                                    {record.comments?.length > maxLength ? (
                                      <Tooltip
                                        isOpen={tooltipOpen === index}
                                        target={`tooltip-${index}`}
                                        toggle={() => toggleTooltip(index)}
                                      >
                                        <h3
                                          style={
                                            isTextUrdu
                                              ? { lineHeight: "1.8" }
                                              : {}
                                          }
                                          className={isTextUrdu ? "ur" : "en"}
                                        >
                                          {record.comments}
                                        </h3>
                                      </Tooltip>
                                    ) : null}
                                  </span>
                                </Col>
                                <Col md="2">
                                  <TbCopy
                                    id={`tooltipCopy-${index}`}
                                    style={{
                                      marginTop: 8,
                                      outline: "none",
                                      cursor: "pointer",
                                    }}
                                    color="#5a9b75 "
                                    size={20}
                                    onClick={() =>
                                      copyToClipboard(record.comments)
                                    }
                                  />
                                  <Tooltip
                                    isOpen={tooltipOpenCopy === index}
                                    target={`tooltipCopy-${index}`}
                                    toggle={() => toggleTooltipCopy(index)}
                                  >
                                    <h5
                                      className={
                                        GetSelectedLanguage() === "ur"
                                          ? "ur"
                                          : "en"
                                      }
                                    >
                                      {"Copy Comment"}
                                    </h5>
                                  </Tooltip>
                                </Col>
                              </Row>
                            ) : null}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              isOpen={record.dropDown}
                              toggle={() => {
                                toggleDropdownOpen(index);
                              }}
                              style={{ position: "static" }}
                            >
                              <DropdownToggle
                                className="btn btn-default"
                                tag="span"
                                aria-expanded={record.dropDown}
                              >
                                {<GetLanguageString props="common_action" />}
                                <b className="caret m-l-xs" />
                              </DropdownToggle>
                              <DropdownMenu
                                style={{
                                  minWidth: "0rem",
                                  fontSize: "13px",
                                }}
                              >
                                <div>
                                  {record.approvalStatus !== 1 &&
                                  record.approvalStatus !== 4 &&
                                  record.canApprove ? (
                                    <DropdownItem
                                      onClick={() => {
                                        setActionModal(true);
                                        setNotificationId(record.id);
                                        setNotificationName(type);
                                        setTransferNotificationUnitId(
                                          type.includes("move_member")
                                            ? record.seeChanges.nextUnitId
                                            : type.includes("move_habib")
                                            ? record.seeChangesHabib.nextUnitId
                                            : loggedInUnitId
                                        );
                                      }}
                                    >
                                      {
                                        <GetLanguageString props="common_take_action" />
                                      }
                                    </DropdownItem>
                                  ) : (record.code === "req_move_member" ||
                                      record.code === "req_move_habib") &&
                                    record.approvalStatus === 1 ? (
                                    <DropdownItem
                                      onClick={() =>
                                        printPdf(
                                          record.id,
                                          record.notificationTypeName
                                        )
                                      }
                                    >
                                      {
                                        <GetLanguageString props="common_print" />
                                      }
                                    </DropdownItem>
                                  ) : null}
                                  <DropdownItem
                                    onClick={() => {
                                      setModal(true);
                                      setValues(arr);
                                      setNotificationName(type);
                                      setDocuments(record.documents);
                                      setSerialNumber(
                                        record.rafiqSerialNumbers
                                      );
                                      setIsSerialNumberCancelled(
                                        record.isSerialNumberCancelled
                                      );
                                      setNotificationId(record.id);
                                    }}
                                  >
                                    {
                                      <GetLanguageString props="member_detail_btn" />
                                    }
                                  </DropdownItem>
                                </div>
                              </DropdownMenu>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>

                <tr>
                  <td colSpan="10">{pageInfo}</td>
                  <td colSpan="11">
                    <ul className={GetCustomStyles().page_style}>
                      <Pagination
                        activePage={pagination.activePage}
                        itemsCountPerPage={pagination.pageSize}
                        totalItemsCount={pagination.totalItem}
                        pageRangeDisplayed={pagination.pageRange}
                        onChange={handlePageChange}
                      />
                    </ul>
                  </td>
                </tr>
              </Table>
            ) : (
              <NoResult />
            )
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </div>
  );
};
export default Notifications;
