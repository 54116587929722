import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { requestApi } from "../../../actions/api";
import { LoadingSpinner } from "../../../constants/const";
import { GetLanguageString } from "../../helper/Components";
import Select from "react-select";
import { ApiNotificationType } from "./ApiNotificationType";
import Draggable from "react-draggable";

const AddNotificationType = ({ setAddModal, setNotificationType }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [notificationTypeValues, setNotificationTypeValues] = useState({
    code: "",
    name: "",
    isApprovalProcess: false,
  });
  const [recipientOptions, setRecipientOptions] = useState([]);
  const [approvalBodyOptions, setApprovalBodyOptions] = useState([]);
  const [selectedRecipientOptions, setSelectedRecipientOptions] = useState([]);
  const [selectedApprovalBodyOptions, setSelectedApprovalBodyOptions] =
    useState([]);
  const [errors, setErrors] = useState(notificationTypeValues);
  const toggle = () => setAddModal(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleDrag = (e, ui) => {
    const { x, y } = position;
    setPosition({ x: x + ui.deltaX, y: y + ui.deltaY });
  };

  const validate = () => {
    let temp = {};
    temp.code = notificationTypeValues.code
      ? ""
      : t("settings_code_error_message");
    temp.name = notificationTypeValues.name
      ? ""
      : t("settings_name_error_message");

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    setLoading(true);
    ApiNotificationType()
      .getResponsibilityOptions()
      .then((res) => {
        setRecipientOptions(res.data);
        setApprovalBodyOptions(res.data);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      code: "",
      name: "",
    });
    setNotificationTypeValues({
      ...notificationTypeValues,
      [name]: value,
    });
  };

  const handleApprovalStatusChange = (e) => {
    const { value } = e.target;
    setNotificationTypeValues({
      ...notificationTypeValues,
      isApprovalProcess: value === "true",
    });
  };

  const handleChangeRecipients = (record) => {
    setSelectedRecipientOptions(record);
    setNotificationTypeValues({
      ...notificationTypeValues,
      recipientIds: record.map((l) => l.value),
    });
  };

  const handleChangeApprovalBodies = (record) => {
    setSelectedApprovalBodyOptions(record);
    setNotificationTypeValues({
      ...notificationTypeValues,
      approvalBodyIds: record.map((l) => l.value),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      requestApi("setupnotificationtype/")
        .create(notificationTypeValues)
        .then((res) => {
          requestApi("setupnotificationtype/")
            .fetchAll()
            .then((res) => {
              toggle();
              setNotificationType(res.data);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
console.log(notificationTypeValues.isApprovalProcess);

  return (
    <div>
      <Draggable position={position} onDrag={handleDrag} cancel=".no-drag">
        <Modal isOpen={true} className="inmodal" autoFocus={false}>
          <ModalHeader className="modal-title" tag="h4">
            {<GetLanguageString props="settings_notification_type_add" />}
          </ModalHeader>
          {!loading ? (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_notification_type_code" />
                    }
                  </Label>
                  <Input
                    autoFocus
                    type="text"
                    name="code"
                    className="no-drag"
                    value={notificationTypeValues.code}
                    onChange={handleInputChange}
                    {...(errors.code && { invalid: true })}
                  />
                  {errors.code && (
                    <FormFeedback>
                      <h4>{errors.code}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>
                    {
                      <GetLanguageString props="settings_notification_type_name" />
                    }
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    className="no-drag"
                    value={notificationTypeValues.name}
                    onChange={handleInputChange}
                    {...(errors.name && { invalid: true })}
                  />
                  {errors.name && (
                    <FormFeedback>
                      <h4>{errors.name}</h4>
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup check>
                  <Label check for="isApprovalProcess">
                    <Input
                      id="isApprovalProcess"
                      type="checkbox"
                      name="isApprovalProcess"
                      value={!notificationTypeValues.isApprovalProcess}
                      onChange={handleApprovalStatusChange}
                      checked={notificationTypeValues.isApprovalProcess}
                    />
                    {
                      <GetLanguageString props="settings_notification_type_is_approval_process" />
                    }
                  </Label>
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">Select Recipients</Label>
                  <Select
                    value={selectedRecipientOptions}
                    options={recipientOptions}
                    className="basic-multi-select ur"
                    classNamePrefix="select"
                    isMulti
                    onChange={handleChangeRecipients}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">
                    Select Approval Bodies
                  </Label>
                  <Select
                    value={selectedApprovalBodyOptions}
                    options={approvalBodyOptions}
                    isDisabled={!notificationTypeValues.isApprovalProcess}
                    className="basic-multi-select ur"
                    classNamePrefix="select"
                    isMulti
                    onChange={handleChangeApprovalBodies}
                  />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="white" onClick={toggle}>
                  {<GetLanguageString props="common_cancel" />}
                </Button>
                <Button color="primary" type="submit">
                  {<GetLanguageString props="common_save_changes" />}
                </Button>
              </ModalFooter>
            </Form>
          ) : (
            LoadingSpinner()
          )}
        </Modal>
      </Draggable>
    </div>
  );
};

export default AddNotificationType;
